import { userCookieName, Snackbar, useCallback, useSelector, useDispatch } from "V3";
import React, { useState, useEffect, lazy, useMemo } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { LicenseManager } from "ag-grid-enterprise";
import { HashRouter } from "react-router-dom";
import AppContext from "./store/AppContext";
import UserCheck from "./UserCheck/UserCheck";
import "react-phone-number-input/style.css";
import "./scss/style.scss";
import axios from "axios";
import headers from "./config/headers";
import ThemeSelector from "./config/theme";
import { metadataConstants } from "./store/constants/metadata";
import { metadataGlobalCorrections } from "./store/actions/metadata";
import { AG_GRID_LICENSE_KEY } from "./constants/Constants";
import { generateAllowedRoutes } from "./routes";
import { openSnackBarIsolated, closeSnackBarIsolated } from "V3/Components/V3LayoutCreator/V3LayoutCreatorFunctions.js";
import { GoogleOAuthProvider } from "@react-oauth/google";

const TheLayout = lazy(() => import("./containers/TheLayout"));
const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

const defaultState = {
    menus: {
        leftSidebar: localStorage.getItem("leftSideBar") === "true",
        rightSidebar: false,
    },
};

const isDevMode = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const App = () => {
    const {
        metadata: { data: metadata },
    } = useSelector((state) => state);
    const [user, setUser] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState();
    const [isPinned, setIsPinned] = useState();
    const [isFullWidth, setIsFullWidth] = useState();
    const [state, setState] = useState(defaultState);
    const resetState = () => setState(defaultState);
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const darkMode = localStorage.getItem("dashfx-darkmode");
        if (darkMode === null) {
            localStorage.setItem("dashfx-darkmode", "true");
            return true;
        }
        return darkMode === "true";
    });
    /**
     * define allowedRoutes
     * This fn is check all routes and it generates allowed routes and menu items
     * depending on user's permissions.
     */
    const [allRoutes, setAllRoutes] = useState();
    const generateRoutes = () => user && setAllRoutes(generateAllowedRoutes(user));
    useEffect(generateRoutes, [user]);

    /**
     * Update metadata
     */
    const dispatch = useDispatch();
    const updateMetadata = () => {
        const fn = async () => {
            console.log("update requested.");
            let getUrl = process.env.REACT_APP_API_URL + "metadata";
            await axios.get(getUrl, headers()).then(
                (result) => {
                    const success = (data) => {
                        return {
                            type: metadataConstants.GET_METADATA_SUCCESS,
                            data,
                        };
                    };
                    dispatch(success(metadataGlobalCorrections(result?.data)));
                },
                (res) => console.log("metadata can not updated."),
            );
        };
        setTimeout(fn, 2000);
    };

    /**
     * set darkState and theme
     */
    const darkState = useMemo(() => (isDarkMode ? "dark" : "light"), [isDarkMode]);
    const theme = createTheme({
        palette: {
            mode: darkState,
            V3IconButtonColor: {
                main: darkState === "dark" ? "#fff" : "#666",
            },
        },
    });

    /**
     * Snackbar
     */
    const [snackBarState, setSnackBarState] = useState();
    const openSnackBar = (msg, type, persistent) => openSnackBarIsolated(msg, type, persistent, setSnackBarState);
    const closeSnackBar = useCallback(() => {
        closeSnackBarIsolated(snackBarState, setSnackBarState);
    }, [snackBarState]);

    return (
        <AppContext.Provider
            value={{
                state,
                setState,
                user,
                setUser,
                resetState,
                userCookieName,
                allRoutes,
                isSidebarOpen,
                setIsSidebarOpen,
                openSnackBar,
                closeSnackBar,
                isPinned,
                setIsPinned,
                metadata,
                isFullWidth,
                setIsFullWidth,
                isDevMode,
                updateMetadata,
                isDarkMode,
                setIsDarkMode,
            }}
        >
            <GoogleOAuthProvider clientId="286956413945-0ugqbbdptjdi3ubdbh7ii5uqe0qtq7vs.apps.googleusercontent.com">
                <ThemeSelector>
                    <ThemeProvider theme={theme}>
                        <HashRouter>
                            <React.Suspense fallback={loading}>
                                <Snackbar snackBarState={snackBarState} handleClose={closeSnackBar} />
                                {!user ? <UserCheck /> : <TheLayout />}
                            </React.Suspense>
                        </HashRouter>
                    </ThemeProvider>
                </ThemeSelector>
            </GoogleOAuthProvider>
        </AppContext.Provider>
    );
};

export default App;
